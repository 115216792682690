import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/nftx-developers.github.io/nftx-developers.github.io/packages/gatsby-theme-carbon/src/templates/Default.js";
import { Connect } from '@carbon/pictograms-react';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const PageDescription = makeShortcode("PageDescription");
const InlineNotification = makeShortcode("InlineNotification");
const ExpressiveListContainer = makeShortcode("ExpressiveListContainer");
const DoDontExample = makeShortcode("DoDontExample");
const Video = makeShortcode("Video");
const Title = makeShortcode("Title");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageDescription mdxType="PageDescription">
      <p>{`The `}<inlineCode parentName="p">{`<ExpressiveListContainer>`}</inlineCode>{` component can be used as a customizable
component.`}</p>
    </PageDescription>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`Disclaimer:`}</strong>{` Nested grid support will be supported in v11. Currently this
component requires custom styles to nest most of the gatsby components into the
container.`}</p>
    </InlineNotification>
    <h2>{`Example`}</h2>
    <ExpressiveListContainer title="Short Title" text="Lorem ipsum tempor incididunt ut labore et dolore magna aliqua. Quam lacus suspendisse faucibus interdum posuere lorem. At quis risus sed lorem ipsum vulputate. Orci ac auctor augue mauris augue neque." mdxType="ExpressiveListContainer">
  <DoDontExample type="do" caption="Caption" captionTitle="Caption title" mdxType="DoDontExample">
    <Video vimeoId="310583077" mdxType="Video" />
  </DoDontExample>
    </ExpressiveListContainer>
    <ExpressiveListContainer title="Short Title" mdxType="ExpressiveListContainer">
  <Video title="Carbon homepage video" vimeoId="322021187" mdxType="Video" />
    </ExpressiveListContainer>
    <h2>{`Code`}</h2>
    <Title mdxType="Title">Content only</Title>
    <pre><code parentName="pre" {...{
        "className": "language-mdx",
        "metastring": "path=components/ExpressiveListContainer/ExpressiveListContainer.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/ExpressiveListContainer",
        "path": "components/ExpressiveListContainer/ExpressiveListContainer.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/ExpressiveListContainer"
      }}>{`<ExpressiveListContainer
  title="Short Title"
  text="Lorem ipsum tempor incididunt ut labore et dolore magna aliqua. Quam lacus suspendisse faucibus interdum posuere lorem. At quis risus sed lorem ipsum vulputate. Orci ac auctor augue mauris augue neque.">
  <DoDontExample type="do" caption="Caption" captionTitle="Caption title">
    <Video vimeoId="310583077" />
  </DoDontExample>
</ExpressiveListContainer>

<ExpressiveListContainer title="Short Title">
  <Video title="Carbon homepage video" vimeoId="322021187" />
</ExpressiveListContainer>
`}</code></pre>
    <h3>{`Props`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`propType`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`required`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`contentClassName`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional prop for custom class names this will be applied to the right column.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`children`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`node`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This prop is used to insert the contents of the container right column`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional prop to insert a custom class name. This class will be applied to the outermost conatiner`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`text`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`node`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional prop to use to insert content text`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`title`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Prop used for a short title`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`titleType`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`expressive-03`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional prop used for type options - default is `}<inlineCode parentName="td">{`expressive-03`}</inlineCode>{`. Options are: `}<inlineCode parentName="td">{`expressive-04`}</inlineCode>{` and `}<inlineCode parentName="td">{`expressive-02`}</inlineCode></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      